#products {
  .ant-table-row {
    cursor: pointer;
  }

  .subheader-btn {
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      button {
        margin-left: 1rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.tabBigScreen{
    display: block;
}
.tabSmallScreen{
    display: none;
}


@media (max-width: 768px) {
    .tabSmallScreen{
        display: block;
    }
    .tabBigScreen{
        display: none;
    }
}
.images{
    .ant-carousel{
        margin-top: 5px;
        height: 250px;
        width: 200px;
    }
    .image-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px 5px;
        width: 200px;
        margin-bottom: 20px;
        overflow: hidden;
    }   
}
.add-category-btn {
  margin-bottom: 1.5rem;
}

.category-list {
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 0;
  }
}

.category-item {
  margin-bottom: 0.6rem;

  .item {
    p {
      margin: 0;
    }

    .category-item-input {
      display: flex;
      align-items: center;

      .formItem {
        margin: 0;
      }

      button {
        margin-left: 15px;
      }
    }
  }

  .edit-btn,
  .delete-btn {
    background: none;
    cursor: pointer;
    border: solid 1px;
  }

  .delete-btn {
    margin-left: 1rem;
  }
}

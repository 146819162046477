.variant-container {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    padding: 8px 2px;
}

.btn {
    cursor: pointer;
  }

  .delete-variant-btn {
    position: absolute;
    right: 0px;
    bottom: 3px;
    background: #ff4d4f;
    color: white;
    padding-top: 0px;
    padding-bottom: 4px;
    border-radius: 15px;
    padding-left: 3px;
    padding-right: 3px;
    font-weight: normal;
  }
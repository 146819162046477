.menu{
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    right: 26px;
    background-color: #fff;
    padding: 29px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .13);
}
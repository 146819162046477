$cartWidth: 350px;

.transaction-extras {
  .send-thank-you-checkbox {
    margin-top: 0.6rem;
    margin-left: 0;
  }
  .anchor-send-thank-you-checkbox {
    cursor: pointer;
    color: #6b6b6b;
    margin-left: 24px;
    text-decoration: underline;
  }
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.sales {
  min-height: 100vh;
  background-color: #fafafa;

  > .ant-layout-header {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.11);
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: transparent;
    padding: 0 !important;
    top: 0px;
    height: auto;
    .main-nav {
      width: 100% !important;
      background-color: #fff;
      padding: 0 50px;
      .menu_small {
        display: none;
      }
      .menu_big{
        display: flex; 
        align-items: center;
      }
      .products_services_switch_small{
        display: none;
        img{
          height: 35px;
          width: 35px;
          max-width: 35px;
          max-height: 35px;
        }
        .anticon {
          margin: 0 10px;
          svg{
            font-size: 20px;
            fill: #7a7a7a;
          }
          &.active {
            svg {
              fill: #b04075;
            }
          }
        }
      }
      h3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 15px;
        // margin-top: 10px;
      }
      .products_services_switch_big {
        display: flex;
        align-items: center;
        gap: 3px;
        img{
          height: 35px;
          width: 35px;
          max-width: 35px;
          max-width: 35px;
        }
        .anticon {
          font-size: 30px;
          margin: 0px 10px 0 10px;
          svg {
            fill: #7a7a7a;
          }
          &.active {
            svg {
              fill: #b04075;
            }
          }
        }
      }
    }

    @media screen and (max-width: 500px) {
      .main-nav {
        padding: 0 10px;
        .products_services_switch_big {
          display: none;
        }
        .menu_big{
          display: none;
        }
        .menu_small{
          display: flex;
          align-items: center; 
          justify-content: center; 
        }
        .products_services_switch_small {
          display: flex;
          align-items: center;
          height: 50px;
          .bundimg{
            height: 25px !important;
            width: 25px;
          }
        }
        h3 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 15px;
          margin-top: 10px;
        }
      }
    }
  }

  .show-bookings-icon {
    font-size: 36px;
    color: #6b6b6b;
    cursor: pointer;
    &:hover,
    &.active {
      color: #D90068;
    }
  }

  main {
    margin-top: 39px;

    .products {
      display: flex;
      position: relative;
      .product-list {
        margin: 0;
        margin-right: 380px;
        padding: 0px;
        list-style: none;
        flex: 1;
        z-index: 1;

        .search-container {
          padding: 20px 20px 5px;
          z-index: 3;
          background: transparent;
        }
        .searchbar-row {
          position: fixed;
          z-index: 20;
          // left: 6%;
          margin-left: -18px;
          margin-right: 5rem;
          background-color: #fff;
          // margin-top: 5px;
          width: 60%;
          @media (max-width: 500px) {
            width: 100%;
            margin-left: 8px;
          }
          @media (max-width: 768px) {
            width: 100%;
            margin-left: 0px;
            padding-left: 15px;
          }
          @media (min-width: 768px) {
            width: calc(100% - 350px);
          }
          @media (min-width: 1024px) {
            width:70%;
          }
          @media (min-width: 1400px) {
            width:65%;
          }
        }

        .item {
          // flex: 0 0 195px;
          width: 100%;
          height: 175px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
          position: relative;
          margin-bottom: 15px;
          text-decoration: none;

          &:hover {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.11);
          }

          &-image-container {
            height: 100%;
            border-radius: 5px;
            display: flex;
            justify-content: center;

            span {
              align-self: center;
              position: relative;
              top: -20px;
              font-size: 2rem;
              text-transform: uppercase;
              color: #ddd;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              object-fit: contain;
            }
          }

          &-details {
            position: absolute;
            width: 100%;
            bottom: 0;
            padding: 10px;
            background-color: rgba(255, 255, 255, 0.96);
            > h5 {
              word-wrap: break-word;
            }
            > div {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .price {
                background-color: #D90068;
                color: #fff;
                display: inline;
                padding: 1px 9px;
                border-radius: 7px;
              }

              .quantity {
                color: #fff;
                padding: 0px 8px;
                border-radius: 13px;
                position: relative;
                top: -2px;

                &.no-stock {
                  background-color: red;
                }

                &.low-stock {
                  background-color: orange;
                }

                &.stocked {
                  background-color: green;
                }

                &.not-tracked {
                  content: "not tracked";
                  background-color: #39addb;
                }
              }
            }
          }
        }
      }

      .cart-button {
        display: none;
      }

      .cart {
        width: $cartWidth;
        right: 0;
        z-index: 1;
        background-color: #fff;
        height: 100%;
        box-shadow: -5px 0px 53px rgba(0, 0, 0, 0.11);
        position: fixed;
        padding-bottom: 100px;
        transition: 0.5s;
        margin-top: 10px !important;

        &-list {
          overflow-y: scroll;
          list-style: none;
          margin: 0px;
          padding: 0px;
          height: 70%;
          padding-bottom: 70px;
          padding-top: 20px;
          @media (max-width: 768px) {
            padding-top: 40px;
          }

          &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 15px;
            border-bottom: 2px solid #fafafa;
            font-size: 13px;

            .image {
              flex: 0 0 38px;
              margin-right: 10px;
            }

            .content {
              display: flex;

              .title {
                margin-bottom: -0px;
              }

              .quantity {
                cursor: pointer;

                &:hover {
                  background-color: #D90068;
                  color: #fff;
                }
              }
            }

            .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
              padding-left: 4px;
              padding-right: 5px;
              font-size: 13px;
              height: 25px;
            }
          }
        }

        &-footer {
          position: fixed;
          width: $cartWidth;
          background-color: #fff;
          bottom: 0;
          height: 32%;
          overflow-y: scroll;

          .sub-container {
            padding: 10px 15px;
            justify-content: flex-end;
            display: flex;
            background-color: #fafafa;
            align-items: center;

            .total-container {
              display: flex;
              flex: 1;
              justify-content: space-between;
              align-items: center;

              h4 {
                margin-bottom: 0px;
              }
            }
          }
        }

        .checkout-btn {
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    main {
      position: relative;
      .products {
        .product-list {
          margin-right: 0px;
          margin-top: 40px;
        }

        .cart-button {
          display: block;
          position: fixed;
          width: 60px;
          background-color: #fff;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 50%;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.23);
          bottom: 11px;
          z-index: 2;
          right: 10px;
          border: 0px;
          outline: 0;

          .number {
            position: absolute;
            // display: block;
            /* top: -25px; */
            background-color: #D90068;
            height: 20px;
            line-height: 1;
            color: #fff;
            font-weight: bold;
            padding: 0 5px;
            border-radius: 5px;
          }
        }

        $cartWidth: 300px;

        .cart {
          right: -400px;
          width: $cartWidth;

          &.open {
            right: 0;
          }

          &-footer {
            width: $cartWidth;
          }
        }
      }
    }
  }
}

// scan qr code draggable modal
.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

#add-customer {
  .ant-input-group-addon {
    padding: 0;
    background-color: unset;
    border: 0;
  }
}

.ant-select-selection__rendered {
  width: 200px !important;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  label {
    color: rgba(0, 0, 0, 0.85);
  }
}

.react-tel-input {
  height: 42px;
  border-radius: 5px;
  input {
    border: 0;
  }
  .flag-dropdown {
    height: 100% !important;
    border-radius: 5px;
    border: 0;
    .selected-flag {
      height: 100% !important;
      border-radius: 5px;
      border: 0;
    }
  }
  &:hover {
    border: 1px solid #bd648c !important;
  }
}
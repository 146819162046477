.logo{
  height: 26px;
    background: rgba(255, 255, 255, 0.2);
    width: 147px;
    margin: 25px auto;
}

.user-role-tag{
  display: inline-block;
    background-color: #D90068;
    color: #fff;
    padding: 0px 10px;
    margin-bottom: 20px;
    border-radius: 7px;
}

.sidebar{
  box-shadow: 6px 2px 5px -4px rgba(240,240,240,0.5);

  &-menu{
    border: none;
  }
}
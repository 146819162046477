.sms_topup_input {
  width: 50%;
}

.page-containerstripe{
  margin-left: 10px;
  .stripe{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .stripeind{
    display: flex;
    align-items: center;
    gap: 20px;
    h3{
      margin: 0;
    }
    p{
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
section.account {
  .ant-tabs-bar {
    padding: 0 1.5rem;
    margin-bottom: 0;
  }

  .bank-screen {
    .active-payout {
      text-align: center;
      max-width: 600px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .custom-input {
    margin-bottom: 24px;
  }

  .sms_topup_input {
    width: 100%;
  }
}

.inventory label{
  font-weight: 700;
  font-size: 16px;
}

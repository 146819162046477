.ordersbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .flexJustify {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .fullwidth {
        width: 100%;
    }
}
#pay {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  > .title {
    text-align: center;
  }

  .pay-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 207px));

    grid-gap: 10px;
    justify-content: center;

    > button {
      // margin: 20px 20px 20px 0;
    }
  }

  .invoice {
    .description {
      padding: 5px;
    }

    .input-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ant-form-item-control-wrapper {
      width: 100% !important;
    }

    .status-wrapper {
      padding: 5px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .status-select {
        width: 100% !important;
      }
    }

    .items-title {
      padding-left: 10px;
    }

    .cart {
      z-index: 1;
      background-color: #fff;
      height: auto;
      overflow: auto;
      max-height: 200px;
      transition: 0.5s;

      &-list {
        overflow-y: scroll;
        list-style: none;
        margin: 0px;
        padding: 0px;
        height: 100%;

        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 15px;
          border-bottom: 2px solid #fafafa;
          font-size: 13px;

          .image {
            flex: 0 0 38px;
            margin-right: 10px;
          }

          .content {
            display: flex;

            .title {
              margin-bottom: -0px;
            }

            .quantity {
              cursor: pointer;

              &:hover {
                background-color: #D90068;
                color: #fff;
              }
            }
          }

          .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
            padding-left: 4px;
            padding-right: 5px;
            font-size: 13px;
            height: 25px;
          }
        }
      }

      &-footer {
        background-color: #fff;
        bottom: 0;
        height: 32%;
        overflow-y: scroll;

        .sub-container {
          padding: 10px 15px;
          justify-content: flex-end;
          display: flex;
          background-color: #fafafa;
          align-items: center;

          .total-container {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;

            h4 {
              margin-bottom: 0px;
            }
          }
        }
      }

      .checkout-btn {
        text-align: center;
      }
    }
  }

  .ml-20 {
    margin-left: 20px;
  }

  .popover-content {
    p {
      cursor: pointer !important;
      border: 1px solid red;
    }

    p:hover {
      background: #ccc !important;
    }
  }

  .payment-history {
    margin-left: 3px !important;
  }

  .info-content {
    width: 150px !important;
  }
}

#pay_with_bank_transfer {
  .banks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 125px));
    grid-gap: 10px;
    justify-content: center;
    margin-top: 20px;

    .card-bank-item {
      text-align: center;
    }

    .links__disabled {
      filter: grayscale(100%);
      pointer-events: none;
      opacity: 1;
    }
  }
}

.custom-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .discount-wrapper {
    .discount-type-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .ant-form {
      display: block;

      .ml-1 {
        margin-left: 1rem;
      }

      .discount-form-item {
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 10px;

        .ant-form-item-control {
          display: flex;

          .ant-form-item-children {
            display: flex;
            align-items: center;

            .custom-discount-field {
              width: 70%;
            }
          }
        }
      }
    }
  }

  .split-payment {
    display: flex;
    align-items: center;
    gap: 5px;
    p{
      margin-bottom: 0;
    }
  }
}
.mt-2 {
  margin-top: 20px;
}

.backdate_calendar {
  .ant-picker-input {
    input {
      display: none;
    }
    .ant-picker-suffix {
      svg {
        color: #D90068;
      }
    }
  }
}

.ant-modal.partnersGiftCard{
  .ant-modal-body{
    margin-top: 3.5rem;
    padding-bottom: 0.1rem;
    input{
      margin-top: 1.5rem;
    }
  }
}

div.signup {
  padding-top: 5vh;

  .logo {
    height: 26px;
    background: rgba(255, 255, 255, 0.2);
    width: 147px;
    margin: 0 auto 2rem;
  }

  > .title {
    margin-bottom: 1em;
  }

  .field-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    label {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  // &-form__container {
  //   background-color: #fff;
  //   padding: 40px 30px;
  //   box-shadow: 0 15px 30px 0 rgba(247, 231, 231, 0.22),
  //     0 5px 15px 0 rgba(0, 0, 0, 0.11);
  //   text-align: left;

  //   .form-utils__container {
  //     display: flex;
  //     justify-content: space-between;
  //     position: relative;
  //     top: 14px;
  //   }
  // }
}

.submit-btn {
  margin-bottom: 1rem;
}

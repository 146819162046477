/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .product-list {
    margin: 0;
    margin-right: 400px;
    padding: 0px;
    list-style: none;
    flex: 1;
    z-index: 1;

    .item {
      // flex: 0 0 195px;
      width: 100%;
      height: 175px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
      position: relative;
      margin-bottom: 15px;
      text-decoration: none;

      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.11);
      }

      &-image-container {
        height: 100%;
        border-radius: 5px;
        display: flex;
        justify-content: center;

        span {
          align-self: center;
          position: relative;
          top: -20px;
          font-size: 2rem;
          text-transform: uppercase;
          color: #ddd;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: contain;
        }
      }

      &-details {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.96);

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .price {
            background-color: #D90068;
            color: #fff;
            display: inline;
            padding: 1px 9px;
            border-radius: 7px;
          }

          .quantity {
            color: #fff;
            padding: 0px 8px;
            border-radius: 13px;
            position: relative;
            top: -2px;

            &.no-stock {
              background-color: red;
            }

            &.low-stock {
              background-color: orange;
            }

            &.not-tracked {
              content: "not tracked";
              background-color: #39addb;
            }
          }
        }
      }
    }
  }

.loader {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-breakdown {
  margin-top: 1rem;

  .total {
    display: flex;
    justify-content: space-between;
  }

  .details {
    border-bottom: solid 1px black;
  }

  p {
    display: flex;
    justify-content: space-between;
  }

  .total-wrapper {
    .subtotal {
      border-bottom: solid 1px black;

      .multiply {
        margin-left: 1.2rem;
      }
    }
  }
}

.renewal-wrapper {
  .payment-option {
    p {
      margin-bottom: 5px;
    }
  }

  .duration {
    button {
      margin-right: 1rem;
    }

    margin-top: 2rem;
  }

  .payment-breakdown {
    margin-top: 1rem;

    .total {
      display: flex;
      justify-content: space-between;
    }

    .details {
      border-bottom: solid 1px black;
    }

    p {
      display: flex;
      justify-content: space-between;
    }

    .total-wrapper {
      .subtotal {
        border-bottom: solid 1px black;

        .multiply {
          margin-left: 1.2rem;
        }
      }
    }
  }
}

.payment-plan {
  margin-top: 1.2rem;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0.5rem;
  }
}

.addons {
  p {
    margin-bottom: 0.5rem;
  }

  .addons-pro {
    display: flex;
    align-items: center;

    .ant-checkbox-wrapper {
      margin-left: 0.5rem;
    }
  }
}

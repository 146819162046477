.progress-overlay {
    height: 100vh;
    // background-color: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    z-index: 10;
    width: 68%;
    padding-left: 20px;
    @media (max-width:768px) {
        padding-left: 0;
        width: 100%;
    }
    .product-progress {
        position: fixed;
        padding: 20px 10px;
        margin: 0 10px;
        top:40%;
        z-index: 10;
        width: 60%;
        display: flex;
        flex-direction: column;
        background-color: white;
        gap: 2px;
        @media (max-width:768px) {
            width: 100%;
            padding: 0;
            top:30%;
            margin: 0;
        }
        .loading {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            line-height: 20px;
            margin-bottom: 0;
        }
        .complete {
            font-size: 18px;
            text-align: center;
        }
    }
}

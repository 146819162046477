.supplierpage {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .item_list {
        display: flex;
        align-items: center;
        justify-content: space-between;


        &p:first-child {
            font-weight: 800;
            font-size: 16px;
        }
    }

}

.flex_display {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
}
.card_supply {
    padding: 5px;
}
@media (max-width:768px) {
    .card_supply {
        padding: 1px;
    }
}
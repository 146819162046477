.upay-disbursements {
  .data-summary {
    span {
      margin-left: 1rem;
    }

    span:first-child {
      margin-left: 0x;
    }
  }
}

$cartWidth: 100%;
.cart {
  width: $cartWidth;
  padding-bottom: 100px;
  transition: 0.5s;

  &-list {
    overflow-y: scroll;
    list-style: none;
    margin: 0px;
    padding: 0px;
    height: 70%;
    padding-bottom: 70px;
    padding-top: 20px;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      border-bottom: 2px solid #fafafa;
      font-size: 13px;

      .image {
        flex: 0 0 38px;
        margin-right: 10px;
      }

      .content {
        display: flex;

        .title {
          margin-bottom: -0px;
        }

        .quantity {
          cursor: pointer;

          &:hover {
            background-color: #D90068;
            color: #fff;
          }
        }
      }

      .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
        padding-left: 4px;
        padding-right: 5px;
        font-size: 13px;
        height: 25px;
      }
    }
  }

  &-footer {
    position: fixed;
    width: $cartWidth;
    background-color: #fff;
    bottom: 0;
    height: 32%;
    overflow-y: scroll;

    .sub-container {
      padding: 10px 15px;
      justify-content: end;
      display: flex;
      background-color: #fafafa;
      align-items: center;

      .total-container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        h4 {
          margin-bottom: 0px;
        }
      }
    }
  }

  .checkout-btn {
    text-align: center;
  }
}

.details-cart {
  max-height: 35vh;
  overflow: auto;
}

@media print {
  #receipt {
    width: 240px;
    font-weight: bold;
    margin-left: 0px;
    margin-right: 10px;
    color: #000000;
    font-family: "Lucida Console", Monaco, monospace;

    .header {
      text-align: center;
      color: #000000;
    }

    .contact-info {
      margin-top: 5px;
      margin-bottom: 8px;
      color: #000000;
      font-size: 10px;

      > span {
        display: block;
        color: #000000;
      }
    }

    .invoice-info {
      margin-bottom: 25px;
      font-size: 12px;
      color: #000000;

      > span {
        display: block;
        color: #000000;
      }
    }

    .bold {
      font-weight: bold;
      color: #000000;
    }

    .padding-left {
      padding-left: 20px;
    }

    .padding-right {
      padding-right: 30px;
    }

    table {
      border-top: 3pt dashed #000000;
      border-bottom: 3pt dashed #000000;
      padding-top: 10pt;
      padding-bottom: 10pt;
      font-size: 12px;
      color: #000000;

      .item-list {
        > td {
          width: 200px;
          padding-bottom: 10px;
          padding-top: 5px;
          padding-left: 20px;
          font-size: 12px;
          color: #000000;
        }
      }
    }

    .total-container {
      margin-top: 30px;
      margin-left: 20px;
      font-size: 13px;
      padding-right: 30px;
      margin-bottom: 40px;

      .subtotal {
        > li {
          display: flex;
          justify-content: space-between;
          color: #000000;
        }

        border-bottom: 1pt solid #000000;
      }

      .total {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1pt solid #000000;
        color: #000000;
      }
    }

    .cash-container {
      .cash {
        display: flex;
        justify-content: space-between;
        border-bottom: 1pt dashed #000;
        color: #000000;
      }

      .to-pay {
        display: flex;
        justify-content: space-between;
        color: #000000;
      }
    }

    ul {
      list-style: none;
      color: #000000;
      margin: 0;
      padding: 0;
    }
  }

  .draftbill {
    width: 240px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    text-align: center;

    .address-info {
      display: block;
      // justify-content: space-between;
      color: #000000;
      margin-top: 1rem;
    }

    .contact-info-draft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
      // border-top: 1px dashed #000;
      // margin-bottom: 8px;
      color: #000000;
      font-size: 12px;
    }

    .contact-info {
      margin-top: 5px;
      margin-bottom: 8px;
      color: #000000;
      font-size: 12px;

      > span {
        display: inline-block;
        color: #000000;
        font-size: 13px;
      }
      .ml-2 {
        margin-left: 0.3rem;
      }
    }

    .invoice-info {
      margin-bottom: 25px;
      font-size: 12px;
      color: #000000;

      > span {
        display: block;
        color: #000000;
      }
    }

    .bold {
      font-weight: bold;
      color: #000000;
    }

    .delievery-note {
      margin-bottom: 1.4rem;

      .bold {
        font-weight: bold;
        color: #000000;
      }
    }
    .delievery_text {
      font-size: 13px;
    }

    table {
      border-top: 3pt dashed #000000;
      border-bottom: 3pt dashed #000000;
      padding-top: 10pt;
      padding-bottom: 10pt;
      font-size: 12px;
      color: #000000;

      .item-list {
        > td {
          width: 200px;
          padding-bottom: 10px;
          padding-top: 5px;
          padding-left: 20px;
          font-size: 12px;
          color: #000000;
        }
      }
    }

    .total-container {
      margin-top: 30px;
      margin-left: 20px;
      font-size: 13px;
      padding-right: 30px;
      margin-bottom: 40px;

      .subtotal {
        > li {
          display: flex;
          justify-content: space-between;
          color: #000000;
        }

        border-bottom: 1pt solid #000000;
      }

      .total {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1pt solid #000000;
        color: #000000;
      }
    }

    .cash-container {
      .cash {
        display: flex;
        justify-content: space-between;
        border-bottom: 1pt dashed #000;
        color: #000000;
      }

      .to-pay {
        display: flex;
        justify-content: space-between;
        color: #000000;
      }
    }

    ul {
      list-style: none;
      color: #000000;
      margin: 0;
      padding: 0;
    }
  }

  .loyalty-balance {
    color: #000000;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    font-size: 11px;
    margin-top: 20px;
  }

  .footer {
    font-size: 11px;

    margin-top: 30px;
    text-align: center;
    padding-right: 10px;
    color: #000000;
  }

  .smallfont {
    font-size: 10px;
    color: #000000;
  }
}
//   .waybill {
//     width: 250px !important;
//     font-weight: bold;
//     margin-left: auto;
//     margin-right: auto;
//     color: #000000;
//     font-family: "Lucida Console", Monaco, monospace;
//     text-align: center;

//     .address-info {
//       display: block;
//       // justify-content: space-between;
//       color: #000000;
//       margin-top: 1rem;
//     }

//
// }

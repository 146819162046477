.app-store {
  & > .ant-card-body {
    padding: 0;
  }

  section.integrations {
    .ant-tabs-bar {
      padding: 0 1.5rem;
      margin-bottom: 0;
    }

    .ant-tabs-nav .ant-tabs-tab {
      margin-right: 20px;
    }

    .integration-card-item {
      border: none;

      .ant-card-body {
        display: flex;
        align-items: center;
        border-radius: 5px;
        background-color: #fff;
        height: 130px;

        .img-wrapper {
          width: 100px;
          height: 100px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .name {
          margin-left: 1rem;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.app_modal {
  width: 620px !important;
}
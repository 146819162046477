body {
  background-color: #fff5f5;

  .page-title {
    font-size: 29px;
    padding-top: 9px;
    color: #a09a9a;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-30 {
    margin-top: 40px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .ml-1 {
    margin-left: 1rem;
  }

  .container {
    max-width: 1240px;
    margin: 0 auto;
  }
}
.grecaptcha-badge { 
  visibility: hidden;
}

// Antd Overrides
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-break: unset;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 2;
  box-shadow: 1px 8px 10px rgba(0, 0, 0, 0.29);
}

.ant-card {
  background: unset;
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.refresh-btn-div{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  Button{
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button{
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .ant-table table {
    display: block;
    overflow-x: scroll;
  }

  .ant-layout-header {
    padding: 0px 18px;
  }
}

@media screen and (max-width: 500px) {
  p,
  span,
  h4,
  h5,
  h6,
  h3 {
    font-size: 14px !important;
  }
}

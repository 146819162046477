.summary{
    font-weight: 700;
}
.ordercomponent{
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 5px;
    .ordersummary{
        h1{
            text-align: center;
            text-transform: uppercase;
            position: fixed;
            left: 5px;
            margin-top: -15px;
            background-color: rgb(255, 245, 245); 
            padding: 10px 11px;
        }
        .pendingorderitems{
            margin-top: 50px;
            p{
                font-size: 22px;
                margin-bottom: 0;
                font-weight: 500;
            }
        }
        padding: 15px 0;
        height: 100vh;
        overflow-y: scroll;
        ::-webkit-scrollbar{
            display: none;
        }
        border-right: 10px solid #fff;
    }
    .orderheading{
        height: 100px;
        // background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        h2{
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;

        }
        .orderrefresh{
            position: absolute;
            right: 50px;
            top: 15px;
            display: flex;
            flex-direction: column;
            gap: 2px;
            align-items: center;
        }
    }
    .orderdetails{
        display: flex;
        flex-direction: column;
        p{
            margin: 0;
        }
        .orderbody{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 5px;
            // overflow-y: scroll;
            height: 80vh;
            .orderitem{
                display: flex;
                flex-direction: column;
                gap: 5px;
                height: 80vh;
                overflow-y: scroll;
                ::-webkit-scrollbar{
                    display: none;
                }
                h3{
                    text-align: center;
                    font-family: 600;
                    margin: 10px 0;
                    position: fixed;
                    height: 50px;
                    background-color: rgb(255, 245, 245);
                    z-index: 1;
                    margin-top: -0.2rem;
                    padding-right: 5%;
                    padding-left: 5%;
                }
                .cover{
                    margin-top: 50px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    /* height: 80vh; */
                    overflow-y: scroll;
                    padding-bottom: 20px;
                }
                .orderstructure{
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    // border-radius: 8px;
                    background-color: #fff;
                    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
                    padding-bottom: 10px;
                    .orderitemhead{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 15px;
                        span{
                            cursor: pointer;
                        }
                    }
                    .orderitembody{
                        // .orderitemothers{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        // }
                        .itemlist{
                            padding: 5px 15px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                    .orderitemfooter{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        button{
                            border-radius: 0px;
                        }
                    }
                }
            }
        }
        .orders{
            .orderstructure{
                display: flex;
                flex-direction: column;
            }
        }
    }
}
.markup_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px;
    .top_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bottom_content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .bottom_cont {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .btn_div {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            .percent_btn {
                border-radius: 50px;
                height: 30px;
                width: 50px;
                cursor: pointer;
                background-color: #fff;
                color: #D90068;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .percent_btn_selected {
                border-radius: 50px;
                height: 30px;
                color: #fff;
                background-color: #D90068;
                width: 50px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
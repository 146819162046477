.ant-table-row {
  cursor: pointer;
}

.sms-details {
  span {
    margin-left: 1rem;
  }
}

.ant-card-bordered.ant-card-hoverable .ant-card-body {
  padding: 10px;
}

.productextra{
  max-height: 200px;
  overflow-y: scroll;
}
.orderprint{
  margin: 30px 0 0 30px;
  // text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .orderprintitems{
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  table {
    border-top: 3pt dashed #000000;
    border-bottom: 3pt dashed #000000;
    padding-top: 10pt;
    padding-bottom: 10pt;
    font-size: 12px;
    color: #000000;

    .item-list {
      > td {
        width: 100px;
        padding-bottom: 10px;
        padding-top: 5px;
        padding-left: 20px;
        font-size: 12px;
        color: #000000;
      }
    }
  }
  .bold {
    font-weight: bold;
    color: #000000;
  }
  .padding-left {
    padding-left: 20px;
  }

  .padding-right {
    padding-right: 30px;
  }
  p,h3{
    margin: 0;
  }
  .contact-info {
    margin-top: 5px;
    margin-bottom: 8px;
    color: #000000;
    font-size: 16px;

    > span {
      display: block;
      color: #000000;
    }
  }
  .footer {
    font-size: 11px;

    margin-top: 30px;
    width: 400px;
    text-align: center;
    padding-right: 10px;
    color: #000000;
  }
  .loyalty-balance {
    color: #000000;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    font-size: 11px;
  }
  .smallfont {
    font-size: 10px;
    color: #000000;
  }
}

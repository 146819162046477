.new-window-container{
  padding: 50px;
}

.butn-div{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
.thisbtn{
  margin-top: 1rem;
  height: 50px;
  width: 150px;
}

.searchresult{
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 0.3rem;
  text-align: center;
  .searchvalue{
    font-size: 30px;
    font-weight: bolder;
  }
}

.variantsdisplay{
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

    p{
      font-size: 20px;
      font-weight: bold;
    }
}

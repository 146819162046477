.variant_input {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  outline: none !important;
  width: 100% !important;
  input {
    text-align: center;
  }
}

.variant_quantity_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .ant-row {
    width: 80% !important;
  }
  button {
    border-radius: 3px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center {
    text-align: center;
  }
}

.variants {
  .type {
    font-size: 14px;
  }
  .variant-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-height: 150px;
    overflow: scroll;
    .variant {
      border: 1px solid rgb(217, 217, 217);
      // width: 140px;
      // h eight: 30px;
      padding: 10px;
      font-size: 12px;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 10px;
      cursor: pointer;
      opacity: 0.9;
    }
    .variant:hover {
      opacity: 1;
    }
    .selected {
      background: #D90068;
      color: white !important;
    }
  }
  .clear-button {
    float: right;
    background: #D90068;
    color: #fff;
  }
}
.auth {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  padding-top: 16vh;
  margin-bottom: 60px;

  > .title {
    margin-bottom: 2em;
  }

  &-form__container {
    background-color: #fff;
    padding: 40px 30px;
    box-shadow: 0 15px 30px 0 rgba(247, 231, 231, 0.22),
      0 5px 15px 0 rgba(0, 0, 0, 0.11);
    text-align: left;

    .form-utils__container {
      display: flex;
      justify-content: space-between;
      position: relative;
      top: 14px;
    }
  }
}

.step-container {
  .title {
    margin: 6rem 0 1rem;
    text-align: center;
  }

  .logo {
    margin: 1rem auto 2rem;
  }

  .steps-content {
    margin-top: 2rem;
  }

  .control-buttons {
    text-align: right;
  }

  .resend-token-link {
    margin-bottom: 1rem;
  }
}

.invoices-table {
    margin-top: 0px !important;
    .filter-holder {
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .ant-table-row {
        cursor: pointer !important;
    }
}
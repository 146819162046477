.product-import-wrapper {
  .tutorial-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tutorial-link {
      font-size: 14px;

      a {
        font-weight: bold;
      }
    }
  }
}

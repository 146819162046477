section.apps-multilevel-loyalties {
  .no-loyalty-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .curr_points_ratio {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.no-loyalty-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.curr_points_ratio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

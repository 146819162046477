#sidebar-menu {
  ul li.ant-menu-item {
    padding: 0;
    padding-left: 20px !important;

    > a {
      padding: 0 24px;

      &.active {
        background-color: #f0e1e6;
        color: #D90068;
        border-right: 3px solid #D90068;
      }
    }
  }
  .my-active {
    background-color: #f0e1e6;
    color: #D90068;
    border-right: 3px solid #D90068;
    padding: 0 24px;
  }
}

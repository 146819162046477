

.modal_1{
    .md_1{
        display: flex;
        flex-direction: column;
        gap: 10px;
        div{
            .ant-row{
                flex-direction: column;
                align-items: flex-start;
            }
            .ant-form-item-control{
                width: 100%;
            }
            .ant-input-number{
                width: 100%;
            }
            p{
                margin-bottom: 0;
            }
            span{
                color: red;
            }
        }
        .btn-div{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            .ant-btn{
                width: 100%;
                height: 40px;
                border-radius: 8px;
                span{
                    color: #fff;
                }
            }
        }
    }
}

.modal_2{
    .md_1{
        display: flex;
        flex-direction: column;
        gap: 10px;
        div{
            p{
                margin-bottom: 0;
                text-align: center;
                margin-bottom: 15px;
            }
            .input-otp{
                input{
                    border-radius: 15px;
                }
            }
        }
        .btn-div{
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            margin-top: 10px;
            .ant-btn{
                width: 40%;
                height: 40px;
                border-radius: 8px;
                span{
                    color: #fff;
                }
            }
            p{
                margin-bottom: 0;
                text-align: center;
                margin-top: 15px;
                span{
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

.modal_3{
    .md_1{
        display: flex;
        flex-direction: column;
        gap: 10px;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            p{
                margin-bottom: 0;
                text-align: center;
                margin-bottom: 5px;
            }
        }
        .btn-div{
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            margin-top: 10px;
            gap: 15px;
            .ant-btn{
                width: 30%;
                height: 40px;
                border-radius: 8px;
                // span{
                //     color: #fff;
                // }
            }
        }
    }
}

.modal_4{
    .md_1{
        div{   
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            p{
                text-align: center;
            }
        }
    }
}
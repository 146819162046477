@font-face {
  font-family: "myFont";
  src: url(../../../../fonts/OCRAEXT.TTF) format("truetype");
}
$font-main: "myFont", sans-serif;

.gift-card {
  width: 50vw;

  .card-number {
    font-weight: 800;
    font-family: $font-main;
    font-size: 32px;
    span {
      margin: 10px;
    }
  }
  .card-expiry {
    font-weight: 800;
    font-family: sans-serif;
    font-size: 18px;

    p {
      font-family: $font-main;
    }
  }
}
